<template>
  <div>
    <search-sim-card
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-dropdown placement="bottomCenter">
          <a-menu slot="overlay" @click="handleBatchOperation">
            <a-menu-item
              v-for="option in batchOperationOptions"
              :key="option.slug"
            >
              {{ option.name }}
            </a-menu-item>
          </a-menu>
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="iccid"
    >
      <div slot="iccid" slot-scope="iccid, record">
        <a @click="showInfoModal(record.id)">{{ iccid }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-sim-card
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <!-- 更新卡信息模态框 -->
    <update-sim-cards-info
      v-if="isShowUpdateInfoModal"
      :visible.sync="isShowUpdateInfoModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <allocate-sim-cards-to-agency
      v-if="isShowAllocateSimCardsToAgencyModal"
      :visible.sync="isShowAllocateSimCardsToAgencyModal"
      :iccids="selectedRowKeys"
    />

    <top-agent-renew-cards
      v-if="this.$store.getters.userRole === 'top_agent' && isShowRenewModal"
      :visible.sync="isShowRenewModal"
      :iccids="selectedRowKeys"
    />

    <agency-renew-cards
      v-if="(this.$store.getters.userRole === 'agency' || this.$store.getters.userRole === 'merchant') && isShowRenewModal"
      :visible.sync="isShowRenewModal"
      :iccids="selectedRowKeys"
    />

    <net-down-sim-cards
      v-if="isShowNetDownModal"
      :visible.sync="isShowNetDownModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <net-up-sim-cards
      v-if="isShowNetUpModal"
      :visible.sync="isShowNetUpModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <set-sim-cards-usage-limit
      v-if="isShowSetUsageLimitModal"
      :visible.sync="isShowSetUsageLimitModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <subscribe
      v-if="isShowSubscribeModal"
      :visible.sync="isShowSubscribeModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <active-expand-pool
      v-if="isShowActiveExpandPoolModal"
      :visible.sync="isShowActiveExpandPoolModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <batch-modify-sim-cards
      v-if="isShowBatchModifyModal"
      :visible.sync="isShowBatchModifyModal"
      :iccids="selectedRowKeys"
    />

    <sms-delivery
      v-if="isShowSmsDeliveryModal"
      :visible.sync="isShowSmsDeliveryModal"
      :iccids="selectedRowKeys"
    />

    <switch-carrier
      v-if="isShowSwitchCarrierModal"
      :visible.sync="isShowSwitchCarrierModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findSimCards, exportSimCards } from '@/api/sim_card'
import Pagination from '@/components/Pagination'
import SearchSimCard from '@/views/sim_cards/Search'
import { findSimCardBatchOperationOptions } from '@/api/batch_operation'
import { exportExcel } from '@/api/excel'

export default {
  name: 'SimCardList',
  components: {
    SearchSimCard,
    Pagination,
    ShowSimCard: () => import('@/views/sim_cards/Show'),
    AllocateSimCardsToAgency: () => import('@/views/batch_operations/AllocateSimCardsToAgency'),
    TopAgentRenewCards: () => import('@/views/batch_operations/renew/TopAgentRenewCards'),
    AgencyRenewCards: () => import('@/views/batch_operations/renew/AgencyRenewCards'),
    NetDownSimCards: () => import('@/views/batch_operations/NetDownSimCards'),
    NetUpSimCards: () => import('@/views/batch_operations/NetUpSimCards'),
    SetSimCardsUsageLimit: () => import('@/views/batch_operations/SetSimCardsUsageLimit'),
    Subscribe: () => import('@/views/batch_operations/Subscribe'),
    ActiveExpandPool: () => import('@/views/batch_operations/ActiveExpandPoolSimCards'),
    UpdateSimCardsInfo: () => import('@/views/batch_operations/UpdateSimCardsInfo'),
    BatchModifySimCards: () => import('@/views/batch_operations/BatchModifySimCards'),
    SmsDelivery: () => import('@/views/batch_operations/SmsDelivery'),
    SwitchCarrier: () => import('@/views/batch_operations/SwitchCarrier')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false, // 是否显示详情弹窗
      showingId: 0, // 正在查看详情的记录id
      batchOperationOptions: [], // 批量操作选项
      isShowUpdateInfoModal: false, // 是否显示数据更新模态框
      isShowAllocateSimCardsToAgencyModal: false, // 是否显示划拨给企业客户的模态框
      isShowSmsDeliveryModal: false, // 是否显示短信下发的模态框
      isShowRenewModal: false, // 是否显示续期模态框
      isShowNetUpModal: false, // 是否显示断网模态框
      isShowNetDownModal: false, // 是否显示恢复上网模态框
      isShowSetUsageLimitModal: false, // 是否显示设置限额模态框
      isShowSubscribeModal: false, // 是否显示设置套餐的模态框
      isShowActiveExpandPoolModal: false, // 是否显示激活扩池的模态框
      isShowBatchModifyModal: false, // 是否显示批量修改模态框
      isShowSwitchCarrierModal: false, // 是否显示切换运营商的模态框
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.fetchAgentSimCardBatchOperationOptions()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: 'IMSI',
          dataIndex: 'imsi'
        },
        {
          title: '短号',
          dataIndex: 'short_number'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '生命周期',
          dataIndex: 'life_cycle'
        },
        {
          title: '联网状态',
          dataIndex: 'net_status'
        },
        {
          title: '划拨对象',
          dataIndex: 'agent_name'
        },
        {
          title: '是否要实名认证',
          dataIndex: 'is_need_verified'
        },
        {
          title: '实名认证状态',
          dataIndex: 'authentication_status'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '客户套餐',
          dataIndex: 'product_name'
        },
        {
          title: '服务周期起始时间(激活时间)',
          dataIndex: 'activated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'activated_at' ? this.sort.sort_order : false
        },
        {
          title: '服务周期结束时间',
          dataIndex: 'service_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'service_end_time' ? this.sort.sort_order : false
        },
        {
          title: '套餐服务起始时间',
          dataIndex: 'product_effective_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'product_effective_time' ? this.sort.sort_order : false
        },
        {
          title: '套餐服务结束时间',
          dataIndex: 'product_expiration_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'product_expiration_time' ? this.sort.sort_order : false
        },
        {
          title: '后续套餐个数',
          dataIndex: 'future_package_count'
        },
        {
          title: '周期开始时间',
          dataIndex: 'cycle_begin_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_begin_time' ? this.sort.sort_order : false
        },
        {
          title: '周期结束时间',
          dataIndex: 'cycle_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_end_time' ? this.sort.sort_order : false
        },
        {
          title: '周期容量',
          dataIndex: 'cycle_total',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_total' ? this.sort.sort_order : false
        },
        {
          title: '周期已用量',
          dataIndex: 'cycle_usage',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_usage' ? this.sort.sort_order : false
        },
        {
          title: '周期剩余量',
          dataIndex: 'cycle_left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_left' ? this.sort.sort_order : false
        },
        {
          title: '后续周期个数',
          dataIndex: 'future_cycle_count'
        },
        {
          title: '订单编号',
          dataIndex: 'order_no'
        },
        {
          title: '数据更新时间',
          dataIndex: 'data_updated_at'
        },
        {
          title: '卡号分组',
          dataIndex: 'group_name'
        },
        {
          title: '设备序列号',
          dataIndex: 'device_sn'
        },
        {
          title: '设备名称',
          dataIndex: 'device_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ]
    },

    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleBatchOperation(e) {
      switch (e.key) {
        case 'update_batch':
          this.isShowUpdateInfoModal = true
          break
        case 'allocate_cards_to_agency':
        case 'allocate_cards_to_merchant':
          this.isShowAllocateSimCardsToAgencyModal = true
          break
        case 'renew':
          this.isShowRenewModal = true
          break
        case 'net_down':
          this.isShowNetDownModal = true
          break
        case 'net_up':
          this.isShowNetUpModal = true
          break
        case 'set_usage_limit':
          this.isShowSetUsageLimitModal = true
          break
        case 'subscribe':
          this.isShowSubscribeModal = true
          break
        case 'active_expand_pool':
          this.isShowActiveExpandPoolModal = true
          break
        case 'batch_modify' :
          this.isShowBatchModifyModal = true
          break
        case 'sms_delivery':
          this.isShowSmsDeliveryModal = true
          break
        case 'switch_carrier':
          this.isShowSwitchCarrierModal = true
          break
      }
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchAgentSimCardBatchOperationOptions() {
      findSimCardBatchOperationOptions().then((res) => {
        if (res.code === 0) {
          this.batchOperationOptions = res.data
        }
      })
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findSimCards(Object.assign({ agent_id: this.agentId },
        this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },
    exportData() {
      exportSimCards(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
